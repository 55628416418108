export const useAppStore = defineStore('app', () => {
  const socialLinks = [
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/childoflight.house',
      icon: 'i-mdi-instagram',
    },
    {
      name: 'Telegram',
      url: 'https://t.me/childoflight1',
      icon: 'i-mdi-telegram',
    },
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/profile.php?id=100080908382539',
      icon: 'i-mdi-facebook',
    },
  ]

  const googleFormLink = 'https://forms.gle/WGCvB8dvQpsVDqYm7'

  return {
    socialLinks,
    googleFormLink,
  }
})
